<template>

	  <div class="">

<div ref="systemreference" style="position: absolute;bottom:-10000px; left: -10000px; height: 1px;">
You will be provided with a project status. Your task is to create a summary of the project status based on the project status provided.<br>
The provided project status is structured in the following way:<br>
The project status consists of one or more project objectives and for each objective there are published status updates. A status update has a status publish date, a status author, a status indicator, and status content. 
If a project objective has more than one status update, then put more emphasis on the latest status update.<br>
Parameters for the summary:<br>
Tone: {{ system_tone }}<br>
Summary length: maximum {{ system_text_length }} characters<br>
Language: {{ system_language }}<br>
Summary style: {{ system_style=='Bullet list' ? 'Bullet list with one bullet for each objective and no sub-bullets' : system_style }}<br><br> 
</div>

	<div ref="userinputreference" style="position: absolute;bottom:-10000px; left: -10000px; height: 1px;">
	
	Project title: {{ board.name }}<br>
	Project status period: from {{ startdate }} to {{ enddate }}<br>
	
	<template v-for="(objective,key) in objectives">
	<template v-if="objective.ischecked">
	Objective title: {{ objective.name }}<br :key="'br1'+key">
	Objective description: {{ domText(objective.description) }}<br :key="'br2'+key">
	<template v-for="(indicator,key2) in objective.indicators">
	<template v-if="indicator.ischecked">
	<template v-if="indicator.metadata || indicatormessage(indicator.message) ">
	Status publish date: {{ indicator.indicatordate | dateformat }}<br :key="'br3'+key+'_'+key2">
	Status author: {{ indicator.userdetail.name }}<br :key="'br4'+key+'_'+key2">
	Status indicator: {{ indicator.color }} = {{ indicator.statusoption.label }}<br :key="'br5'+key+'_'+key2">
	Status content: {{ indicator.metadata ? indicator.metadata.usertext : indicatormessage(indicator.message) }} <br :key="'br6'+key+'_'+key2">
	<br :key="'br7'+key+'_'+key2">

	<template v-if="objective.measuretype=='risk'">
	The 3 biggest risks: <br :key="'br8'+key+'_'+key2">
	<template v-for="(item,key3) in topRisks(indicator)">
	{{key3+1}}. {{item.label}}. {{item.description}}<br :key="'br9b'+key+'_'+key3">
	</template>
	</template>

	<template v-if="objective.measuretype=='masterplan'">
	<template v-if="delayedMilestones(indicator)">
	<template v-if="delayedMilestones(indicator).length > 0">
	Delayed milestones: <br :key="'br10'+key2">
	<template v-for="(item,key3) in delayedMilestones(indicator)">
	- Milestone ‘{{ item.delay.label }}’, with planned date = {{ item.delay.eventdate }}. A milestone on the {{ item.track.name }} track<br :key="'br11'+key+'_'+key3">
	</template>
	</template>
	<template v-else>
	No delayed milestones<br :key="'br12'+key2">
	</template>
	</template>
	</template>

	<br v-if="key2 <= objective.indicators.length" :key="'br17'+key+'_'+key2">
	</template>

	</template>

	</template> 
	<br :key="'br18'+key">

	</template>
	</template>
	</div>


	  	<div class="card m-2 p-2 clearfix d-flex" style="width: 920px;">
	  	Builder

		<form class="row g-3">
		<div class="col-md-2">
		<label for="inputEmail4" class="form-label">Tone</label>
		<br>
		<select id="inputState" class="form-select form-control" v-model="system_tone">
		<option>Professional</option>
		<option>Friendly</option>
		<option>Casual</option>
		</select>
		</div>
		<div class="col-md-2">
		<label for="inputPassword4" class="form-label">Length</label>
		<input type="number" class="form-control" id="inputPassword4" min="400" max="1000" step="20" v-model="system_text_length">
		</div>
		<div class="col-md-2">
		<label for="inputEmail4" class="form-label ">Language</label>
		<br>
		<select id="inputState" class="form-select form-control" v-model="system_language">
		<option selected>English</option>
		<option>Danish</option>
		</select>
		</div>

		<div class="col-md-3">
		<label for="inputEmail4" class="form-label ">Style</label>
		<br>
		<select id="inputState" class="form-select form-control" v-model="system_style">
		<option selected>1 paragraph</option>
		<option>2 paragraph</option>
		<option>Bullet list</option>
		</select>
		</div>

		<div class="col-md-3">
			<label for="inputEmail4" class="form-label ">&nbsp;</label>
		<br>
		<button v-if="1==2" class="btn btn-primary" @click.prevent.stop="builduser()">User</button>
		<button v-if="!working" class="btn btn-primary" @click.prevent.stop="builduser( true )">Run</button>
		<button v-if="working" class="btn btn-primary" disabled="disabled">Run</button>
		</div>

	
		</form>

	  	


		
	  	</div>

	  	<div class="card m-2 p-2 float-left" style="width: 600px; min-height: 900px;">
		  <b-tabs content-class="mt-3">
		    <b-tab title="Response" active>
		    	<div v-html="formatresponse(gptresponse)"></div>
		    </b-tab>
		    <b-tab title="System">
		    	<div style="width: 100%;" v-html="textDom(systemtext)"></div>
			</b-tab>
		    <b-tab title="User">		    	
		    	<textarea style="width: 100%;min-height: 300px;" v-model="builtusertext"></textarea>
		    	
		    	<input type="form-control" name="" v-model="requery" style="width:500px;">
		    	<button v-if="!working" type="button" class="btn btn-secondary float-right" @click="resubmit()">Re-submit</button>
		    	<button v-if="working" type="button" class="btn float-right" disabled>Re-submit</button>

		    </b-tab>
		  </b-tabs>
		</div>

	  	<div class="card m-2 p-2 float-left" style="width: 300px; max-height: 800px; overflow: auto;">

	  		<div>
	  		<div style="width: 70px; " class="mb-1">
		  	<label for="startdate"  class="mb-0 p-0">Start date:</label>
	        <input type="date" id="startdate" name="startdate" v-model="startdate">
			</div>

			<div style="width: 70px;" >
		  	<label for="enddate" class="mb-0 p-0">End date:</label>
	        <input type="date" id="enddate" name="enddate" v-model="enddate">
			</div>

			<div class="mt-2 mb-2">
				<button class="btn btn-primary" @click="loadindicators()">Load objectives</button>
			</div>
			
			</div>
	  		
	  		<ul class="list-group border-0">
			  	<li v-for="(objective,key) in objectives" :key="key" class="list-group-item p-2">
			  		
			  		<div class="h5"> <input type="checkbox" v-model="objective.ischecked" @click="updateselected(objective)">
			  		{{ objective.name }}</div>
			  		<div> {{ domText(objective.description)  }} </div>
				  	<ul class="list-group pl-2">
				  	  <template v-for="(indicator,key2) in objective.indicators">
					  <li v-if="indicator.metadata || indicatormessage(indicator.message)" :key="'metadata_'+key2" class="list-group-item p-1">
				  	
					  	<h5> <input type="checkbox" v-model="indicator.ischecked" @click="updateselectedindicators()"> 
					  	{{ indicator.indicatordate | dateformat }}</h5>
					  	By: {{ indicator.userdetail.name }} <br>
					  	{{ indicator.color }} = {{ indicator.statusoption.label }}
					  	<p v-if="indicator.metadata && indicator.metadata.usertext" v-html="indicator.metadata.usertext"></p>
					  	<p v-else v-html="indicatormessage(indicator.message)"></p>

					  	<template v-if="objective.measuretype=='risk'">
					  		The 3 biggest risks: <br>
					  		<template v-for="(item,key3) in topRisks(indicator)">
							<div :key="'risk_'+key3">{{key3+1}}.	{{item.label}}<br>{{item.description}}</div>
							</template>
						</template>
					  	
					  	<template v-if="objective.measuretype=='masterplan'">
					  	<template v-if="delayedMilestones(indicator)">
						  	<div v-if="delayedMilestones(indicator).length > 0">

						  		Delayed milestones: 
						  		
								<div v-for="(item,key3) in delayedMilestones(indicator)" :key="'milestone_'+key3">
									{{ item.delay.label }}. Scheduled date = {{ item.delay.eventdate }}. A milestone on the {{ item.track.name }} track
							  	</div>
						  	</div>
						  	<div v-else>
						  		No delayed milestones
						  	</div>
					  	</template>
					  	</template>
					  	
					  	

					  </li>
					  </template>
					</ul>
				</li>
			</ul>

	  	</div>
	  </div>


</template>

<style type="text/css">
	
	.h5{
		font-size: 15px;
		font-weight: bold;
	}

</style>


<script type="text/javascript">
	
</script>

<script>
	
	import axios from 'axios'
	//import settrelloboard from '@/views/tools/trello/settrelloboard'	

	export default{
		name:'aibuilder',

		props:{
		},

		data(){
			return{
				newconfig:{},
				requery:"",
				gptresponse:"",
				indicators:[],
				board:{},
				objectives:{},
				builtusertext:"",
				system_tone:"Professional",
				system_text_length:"400",
				system_language:"English",
				system_style:"Bullet list with one bullet for every objective",
				systemtext:"",
				builtusertext:"",
				working:false,
				startdate:"",
				enddate:""
			}
		},
		props:{
		},

		watch:{
			
		},
		mounted(){


			this.loadindicators()

		},
		methods:{

			indicatormessage( message ){

				//var div = document.createElement('div');
				//div.innerHTML = message;
				//var content = content.replace(/<img[^>]*>/g,"");
				return message.replace(/<img[^>]*>/g,"");
			},

			resubmit(){

				//console.log( this.newconfig.)

				//let usertext = ""

				//this.builtusertext = usertext

				//if( makerequest ){

				//	setTimeout( function(){

				let config = this.newconfig

				config.messages.push( {
				"role": "user",
				"content": this.requery
				} )

				this.makegptrequest( config )
				//	}, 200)

				//}

			},

			updateselectedindicators(){

				let self = this

				setTimeout( function(){
					self.builduser( false )	
				},200)
			},

			updateselected( objective ){

				let self = this

				setTimeout( function(){
					self.builduser( false )	
				},200)
				
				setTimeout( function(){
					for( let i in objective.indicators ){
						objective.indicators[i].ischecked = objective.ischecked// ? false : true
					}
				},100)

				




			},

			topRisks(indicator){

				let metadata = indicator.metadata ? indicator.metadata : {}

				if( !metadata.risks ){
					return null
				}

				let risks = []
				for( let i in metadata.risks ){
					if( metadata.risks[i].status != 'closed' ){
			            risks.push( metadata.risks[i]  )
			          }
				}

				return risks
			},

			delayedMilestones( indicator ){

				let metadata = indicator.metadata ? indicator.metadata : {}

				if( !metadata.tracks ){
					return null
				}

				let milestonescount = 0



				let delayed = []

				for( let i in metadata.tracks ){

					let track = metadata.tracks[i]

					if( track.milestones ){

						milestonescount += track.milestones.length

						for( let o in track.milestones ){

							if( track.milestones[o].status.toLowerCase() == "red" ){
								delayed.push( { "track": { "id": track._id, "name": track.label } , delay: track.milestones[o] }  )
							}
							
						}
						
					}

					
				}

				if( milestonescount == 0 ){
					return null
				}

				return delayed

			},

			builduser( makerequest ){

				let self = this

				this.systemtext = this.$refs.systemreference.innerText
				let usertext = this.$refs.userinputreference.innerText

				this.builtusertext = usertext

				if( makerequest ){

					setTimeout( function(){
						self.makegptrequest()
					}, 200)

				}

				
			},

			formatresponse( str ){

				let newstr = str.replaceAll("\n","<br>")
				return newstr
			},


			async makegptrequest( newconfig ){

			  this.working = true
			  this.gptresponse = "Working..."

			  let self = this


			  let config = {
			  "model": "gpt-4-1106-preview",
			  "messages": [
				{
				"role": "system",
				"content": this.systemtext
				},
				{
				"role": "user",
				"content": this.builtusertext
				}
			  ],
			  "temperature": 1,
			  "max_tokens": 1000,
			  "top_p": 1,
			  "frequency_penalty": 0,
			  "presence_penalty": 0
			}

			if( newconfig ){
				config = newconfig
			}			


			const CHAT_GPT_KEY = VUE_APP_OPENAI_KEY;
			
			    const data = config
			    // Make the API call to ChatGPT


			    const response = await fetch('https://api.openai.com/v1/chat/completions', {
			      method: 'post',
			      headers: {
			        'Content-Type': 'application/json',
			        Authorization: `Bearer ${CHAT_GPT_KEY}`
			      },
			      body: JSON.stringify(data)
			    });
			    const { choices } = await response.json();
			    // Insert the suggestion into selected text
			    self.gptresponse = choices[0].message.content

			    config.messages.push( choices[0].message )

			    this.newconfig = config

			    if( choices ){
			    	this.working = false
			    }

			},

			textDom( htmlstr ){

				return htmlstr.replaceAll("\n", "<br>")
			},


			domText( htmlstr ){

				const parser = new DOMParser();
				let existinghtml = parser.parseFromString(htmlstr, 'text/html');
				return existinghtml.body.innerText

			},

			loadindicators(){

				let self = this
				this.working = true

				let url = "https://dev2api.flowe.io"
				//let url = "http://localhost:8081"

				axios.get( url + "/Tools/Loadbuilder?cid="+this.cockpitid+"&startdate="+this.startdate+"&enddate="+this.enddate ).then( function( response ){
					self.working = false
					self.board = response.data.board
					self.objectives = response.data.objectives
					self.system_text_length = self.objectives.length * 100
				})
			},


		},
		computed:{

			

			cockpitid(){
        		return this.$route.params.cid
     		},



		},
		components:{ },

	}
	//  
</script>